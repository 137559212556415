import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const Navigation = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    // Fonction pour détecter le changement de taille d'écran
    const handleResize = () => {
      if (window.innerWidth > 400) {
        setShowMenu(false); // Ferme le menu si la largeur dépasse 400px
      }
    };

    // Ajoute un écouteur d'événement pour redimensionner la fenêtre
    window.addEventListener("resize", handleResize);

    // Nettoie l'écouteur d'événement lorsque le composant est démonté
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Utilise une dépendance vide pour exécuter cet effet une seule fois au montage

  return (
    <header>
      <div className='header-container container'>
        <NavLink to='/' className='logo-link'>
          <img
            src='./assets/logos/orphynou-logo-mini.webp'
            alt='logo site orphynou'
          />
        </NavLink>
        <nav className={`${showMenu ? "show" : ""}`}>
          <div className='menu-icon' onClick={toggleMenu}>
            <div className={`line ${showMenu ? "open" : ""}`}></div>
            <div className={`line ${showMenu ? "open" : ""}`}></div>
            <div className={`line ${showMenu ? "open" : ""}`}></div>
          </div>
          <ul>
            <li>
              <NavLink to='/' onClick={toggleMenu}>
                Accueil
              </NavLink>
            </li>
            <li>
              <NavLink to='/about' onClick={toggleMenu}>
                Présentation
              </NavLink>
            </li>
            <li>
              <NavLink to='/works' onClick={toggleMenu}>
                Réalisations
              </NavLink>
            </li>
            <li>
              <NavLink to='/contact' onClick={toggleMenu}>
                Contact
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Navigation;
