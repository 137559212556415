import React from "react";
import ContactMe from "../components/ContactMe";

const Contact = () => {
  return (
    <main>
      <div className='contact-container container common-container'>
        <h2 className='menu-title'>
          <img
            src='./assets/title/contact-title.webp'
            alt='logo graphique pour la page Contact'
          />
        </h2>
        <div className='contact-content'>
          <div className='contact-left'>
            <p>
              Si vous avez des questions ou besoin d'informations
              supplémentaires, n'hésitez pas à me contacter en utilisant le
              formulaire ci-dessous ou en m'envoyant un e-mail via le bouton en
              bas de la page.
            </p>
            <p>Je serais ravi de répondre à toutes vos demandes.</p>
            <img
              src='./assets/illustrations/contact-pic.webp'
              alt='illustration pour la page contact'
            />
          </div>
          <ContactMe />
        </div>
      </div>
    </main>
  );
};

export default Contact;
