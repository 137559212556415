import React from "react";

const Hexagon = ({ work, onClick }) => {
  const handleClick = () => {
    onClick(work.id);
  };
  return (
    <article onClick={handleClick}>
      <div className='image-container'>
        <img src={work.logo.fond} alt={`logo de ${work.title}`} />
        <div className='img-overlay'>
          <img src={work.logo.hover} alt={`logo alternatif de ${work.title}`} />
        </div>
      </div>
    </article>
  );
};

export default Hexagon;
