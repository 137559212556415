import axios from "axios";
import React, { useEffect, useState } from "react";
import Hexagon from "../components/Hexagon";
import Modal from "../components/Modal";
import ModalContent from "../components/ModalContent";

const Works = () => {
  const [data, setData] = useState([]);
  const [selectedWorkId, setSelectedWorkId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `./data/works.json?t=${new Date().getTime()}`
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const generateModalContent = (workId) => {
    const selectedWork = data.find((work) => work.id === workId);
    if (!selectedWork) {
      return null;
    }
    return <ModalContent selectedWork={selectedWork} />;
  };

  useEffect(() => {
    if (selectedWorkId !== null) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [selectedWorkId]);

  return (
    <main>
      <div className='works-container container common-container'>
        <h2 className='menu-title'>
          <img
            src='./assets/title/works-title.webp'
            alt='logo graphique pour la page Réalisation'
          />
        </h2>
        <div className='hexa-container'>
          <section>
            {data.map((work) => (
              <Hexagon key={work.id} work={work} onClick={setSelectedWorkId} />
            ))}
          </section>
        </div>
      </div>
      <Modal
        open={selectedWorkId !== null}
        onClose={() => setSelectedWorkId(null)}
      >
        {selectedWorkId !== null && generateModalContent(selectedWorkId)}
      </Modal>
    </main>
  );
};

export default Works;
