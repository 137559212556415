import React from "react";

const Home = () => {
  return (
    <main>
      <div className='home-container container common-container'>
        <div className='main-left'>
          <h1 className='logo-title'>
            <img
              src='./assets/logos/orphynou-logo-main.webp'
              alt='logo orphynou'
              className='logo-orphynou'
            />
          </h1>
          <div className='text-left'>
            <p className='green-txt line1'>Bonjour !</p>
            <p className='blue-txt line2'>Je suis </p>
            <p className='blue-txt line3'>Anthony Savivanh</p>
            <p className='green-txt line4'>Alias Orphynou</p>
            <p className='green-txt line5'>Développeur front-end</p>
          </div>
        </div>
        <div className='main-right'>
          <picture>
            <source
              media='(max-width: 500px)'
              srcSet='./assets/illustrations/home-pic-mobile.webp'
            />
            <img
              src='./assets/illustrations/home-pic.webp'
              alt='visuel de moi au travail'
              className='portrait-main'
            />
          </picture>
        </div>
      </div>
    </main>
  );
};

export default Home;
