import React, { useEffect, useState } from "react";

const Modal = ({ open, onClose, children }) => {
  const [display, setDisplay] = useState(false);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (open) {
      setDisplay(true);
      setAnimate(true);
    } else {
      setAnimate(false);
      setTimeout(() => setDisplay(false), 500);
    }
  }, [open]);
  const handleClose = () => {
    onClose();
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    display && (
      <div
        className={`modal-overlay ${animate ? "open" : ""}`}
        onClick={handleOverlayClick}
      >
        <div className='modal-content'>
          <button className='close-button' onClick={handleClose}>
            X
          </button>
          {children}
        </div>
      </div>
    )
  );
};

export default Modal;
