import React from "react";

const About = () => {
  return (
    <main>
      <div className='about-container container common-container'>
        <h2 className='menu-title'>
          <img
            src='./assets/title/about-title.webp'
            alt='logo graphique pour la page Présentation'
          />
        </h2>
        <div className='about-content'>
          <div className='about-left'>
            <picture>
              <source
                media='(max-width: 500px)'
                srcSet='./assets/illustrations/about-pic-mobile.webp'
              />
              <img
                src='./assets/illustrations/about-pic.webp'
                alt='illustration pour la page de présentation'
              />
            </picture>
          </div>
          <div className='about-right'>
            <p>
              Fort de 9 années d'expérience en tant que technicien de support
              informatique et responsable de session d'examen au sein d'un
              centre de formation, j'ai entrepris une reconversion
              professionnelle dans le domaine du développement.
            </p>
            <p>
              J'ai suivi chez OpenClassrooms, une formation de développeur web
              (titre RNCP de niveau 5), où j'ai découvert une passion pour
              JavaScript et React.
            </p>
            <p>
              Animé par le désir d'approfondir mes compétences, je souhaite
              explorer de nouveaux horizons avec des langages tels que Python
              pour l'intelligence artificielle et Swift pour le développement
              mobile dans l'écosystème Apple.
            </p>
            <p>
              Je suis impatient de partager avec vous mes réalisations et mes
              projets à travers ce portfolio. N'hésitez pas à parcourir mes
              travaux et à me contacter si vous souhaitez en savoir plus ou
              collaborer sur vos futurs projets web !
            </p>
          </div>
        </div>
        <div className='langage-container'>
          <h3>
            <img
              src='./assets/title/langage-title.webp'
              alt='représentatoin de la lettre L'
            />
          </h3>

          <div className='langage-icons'>
            <img
              src='./assets/logos/hexa-html.webp'
              alt='logo graphique de HTML'
            />
            <img
              src='./assets/logos/hexa-css.webp'
              alt='logo graphique de HTML'
            />
            <img
              src='./assets/logos/hexa-js.webp'
              alt='logo graphique de HTML'
            />
            <img
              src='./assets/logos/hexa-swift.webp'
              alt='logo graphique de HTML'
            />
            <img
              src='./assets/logos/hexa-python.webp'
              alt='logo graphique de HTML'
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default About;
