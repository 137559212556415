import React, { useEffect, useState } from "react";

const Footer = () => {
  const firstPart = "sh.anthony";
  const secondPart = "icloud.com";

  const [emailAddress, setEmailAddress] = useState("");
  useEffect(() => {
    const email = `${firstPart}@${secondPart}`;
    setEmailAddress(email);
  }, []);

  return (
    <footer>
      <div className='footer-container container'>
        <ul className='footer-logo-container'>
          <li>
            <a
              href='https://www.linkedin.com/in/anthony-savivanh/'
              target='_blank'
              rel='noreferrer'
              aria-label='lien vers ma page linkedin'
            >
              <span className='linkedin-icon media-icon'></span>
            </a>
          </li>
          <li>
            <a
              href='https://github.com/orphynou'
              target='_blank'
              rel='noreferrer'
              aria-label='lien vers ma page git'
            >
              <span className='git-icon media-icon'></span>
            </a>
          </li>
          <li>
            <a
              href='https://discordapp.com/users/214304671957778432'
              target='_blank'
              rel='noreferrer'
              aria-label='lien vers mon profil discord'
            >
              <span className='discord-icon media-icon'></span>
            </a>
          </li>
          <li>
            <a
              href={"mailto:" + emailAddress}
              target='_blank'
              rel='noreferrer'
              aria-label='lien pour envoyer un email'
            >
              <span className='mail-icon media-icon'></span>
            </a>
          </li>
        </ul>
        <p>ⓒ 2024 Anthony SAVIVANH. Tous droits réservés</p>
      </div>
    </footer>
  );
};

export default Footer;
