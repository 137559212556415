//import Masonry from "masonry-layout";
import React from "react";
import Tag from "./Tag";
import Masonry from "react-masonry-css";

const ModalContent = ({ selectedWork }) => {
  return (
    <div className='modal-container'>
      <div className='modal-title'>
        <img
          src={selectedWork.banner}
          alt={`logo titre de ${selectedWork.title}`}
        />
      </div>
      <div className='modal-main'>
        <div className='modal-main-left'>
          <img
            src={selectedWork.cover}
            alt={`bannière de ${selectedWork.title}`}
          />
        </div>
        <div className='modal-main-right'>
          <div className='modal-tag'>
            <ul className='tag-ul'>
              {selectedWork.tags.map((tag, index) => (
                <Tag key={index} tag={tag} />
              ))}
            </ul>
          </div>
          <div className='modal-desc'>
            <p className='intro-work'>
              {selectedWork.description.introduction}
            </p>
            <h3>Objectif :</h3>
            <p className='font-light'>{selectedWork.description.objectif}</p>
            <h3>Compétences :</h3>
            <ul>
              {selectedWork.description.skills.map((skill, index) => (
                <li className='font-light' key={index}>
                  {skill}
                </li>
              ))}
            </ul>
            <h3>Informations Complémentaires :</h3>
            <p className='font-light'>{selectedWork.description.info}</p>

            {selectedWork.link ? (
              <>
                <h3>Lien vers mon code Git ou le site :</h3>
                <a href={selectedWork.link} target='_blank' rel='noreferrer'>
                  Lien vers github
                </a>
                <br />
                {selectedWork.linksite ? (
                  <a
                    href={selectedWork.linksite}
                    target='_blank'
                    rel='noreferrer'
                  >
                    Lien vers le site
                  </a>
                ) : (
                  <span>Pas de lien disponible</span>
                )}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className='screen-title'>
        <img src='./assets/title/screen-title.webp' alt='' />
      </div>
      <Masonry
        breakpointCols={{ default: 2, 900: 1 }}
        className='modal-screen'
        columnClassName='modal-screen-grid'
      >
        {selectedWork.picture.map((screen, index) => (
          <img
            key={index}
            src={screen}
            alt={`capture d'écran de ${selectedWork.title}`}
          />
        ))}
      </Masonry>
    </div>
  );
};

export default ModalContent;
