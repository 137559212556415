import emailjs from "@emailjs/browser";
import React, { useRef } from "react";

const ContactMe = () => {
  const form = useRef();
  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Récupérer les valeurs des champs
    let isValid = true; // Initialise une variable pour suivre la validation globale du formulaire

    // Récupérer les valeurs des champs
    const name = document.getElementById("lastname").value;
    const firstname = document.getElementById("firstname").value;
    const email = document.getElementById("email").value;
    const subject = document.getElementById("subject").value;
    const message = document.getElementById("text-area").value;

    // Récupérer les éléments span pour afficher les messages d'erreur
    const nameError = document.getElementById("name-error");
    const firstnameError = document.getElementById("firstname-error");
    const emailError = document.getElementById("email-error");
    const subjectError = document.getElementById("subject-error");
    const messageError = document.getElementById("message-error");

    // Fonction pour afficher les erreurs
    const showError = (element, message) => {
      element.innerText = message; // Affiche le message d'erreur
      element.classList.add("show"); // Affiche l'élément
      isValid = false; // Met à jour la variable isValid
    };

    // Fonction pour masquer les erreurs
    const hideError = (element) => {
      //element.innerText = ""; // Efface le message d'erreur
      element.classList.remove("show"); // Masque l'élément
    };

    // Validation du champ Nom
    name === ""
      ? showError(nameError, "Veuillez saisir votre nom.")
      : hideError(nameError);

    // Validation du champ Prénom
    firstname === ""
      ? showError(firstnameError, "Veuillez saisir votre prénom.")
      : hideError(firstnameError);

    // Validation du champ Email
    email === ""
      ? showError(emailError, "Veuillez saisir votre adresse e-mail.")
      : !emailRegex.test(email)
      ? showError(emailError, "Veuillez saisir une adresse e-mail valide.")
      : hideError(emailError);

    // Validation du champ Sujet
    subject === ""
      ? showError(subjectError, "Veuillez saisir le sujet de votre message.")
      : hideError(subjectError);

    // Validation du champ Message
    message === ""
      ? showError(messageError, "Veuillez saisir votre message.")
      : hideError(messageError);

    return isValid; // Retourne true si le formulaire est valide, sinon false
  };

  const sendEmail = (e) => {
    e.preventDefault();

    // Validation du formulaire
    if (!validateForm()) {
      return;
    }
    // Envoyer l'e-mail
    emailjs
      .sendForm("service_hxaakkr", "template_o00qj09", form.current, {
        publicKey: "wfXjtq0zVgc67ENE4",
      })
      .then(
        () => {
          alert("Votre message a été envoyé avec succès!");
          document.getElementById("form-id").reset();
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <form id='form-id' ref={form} onSubmit={sendEmail}>
      <div className='lastname-container form-common'>
        <label htmlFor='lastname'>Nom</label>
        <input type='text' name='user_name' placeholder='Nom' id='lastname' />
        <span id='name-error' className='error-message'>
          Veuillez saisir votre nom.
        </span>
      </div>
      <div className='firstname-container form-common'>
        <label htmlFor='firstname'>Prénom</label>
        <input
          type='text'
          name='user_firstname'
          placeholder='Prénom'
          id='firstname'
        />
        <span id='firstname-error' className='error-message'>
          Veuillez saisir votre prénom.
        </span>
      </div>
      <div className='email-container form-common'>
        <label htmlFor='email'>Email</label>
        <input type='text' name='user_email' placeholder='Email' id='email' />
        <span id='email-error' className='error-message'>
          Veuillez saisir votre adresse e-mail.
        </span>
      </div>
      <div className='subject-container form-common'>
        <label htmlFor='subject'>Sujet</label>
        <input
          type='text'
          name='user_subject'
          placeholder='Sujet'
          id='subject'
        />
        <span id='subject-error' className='error-message'>
          Veuillez saisir le sujet de votre message.
        </span>
      </div>
      <div className='message-container form-common'>
        <label htmlFor='text-area'>Votre message</label>
        <textarea name='message' placeholder='Votre message' id='text-area' />
        <span id='message-error' className='error-message'>
          Veuillez saisir votre message.
        </span>
      </div>
      <span id='subject-error' className='error-message'></span>
      <input type='submit' value='Envoyer mon message' id='btn-submit' />
    </form>
  );
};

export default ContactMe;
